import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import { baseAPI } from '../../services/baseAPI';
import { Edit } from 'react-feather';

function EditStock() {
  const { id, detailid } = useParams();
  const history = useHistory();
  const ADMIN = 1;
  const token = useUserStore((state) => state.token);
  const user = useUserStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);


  // CONTROLLER

  const fetchEditStock = async (id) => {
    setIsLoading(true);
    await baseAPI
      .get(`/v1/stock/${id}/${detailid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data.data.stock
        if (data.length > 0) {
          // Swal.fire({
          //   icon: 'success',
          //   title: `Berhasil mengambil data`,
          //   showConfirmButton: false,
          //   timer: 1500
          // });
          setData(data);
        } else {
          Swal.fire({
            icon: 'error',
            title: `Data tidak tersedia.`,
            showConfirmButton: false,
            timer: 1500
          })
        };
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: 'error',
          title: `Gagal mengambil data`,
          showConfirmButton: false,
          timer: 1500
        })
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  const updateStock = async (id) => {
    Swal.fire({
      title: 'Do you want to update the stock?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const body = new FormData();
        body.append('update_data', JSON.stringify(data));
        // console.log(body.get('update_data'))
        baseAPI
          .put(`/v1/stock/update/${id}/${detailid}`, body, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: "Stock has been updated.",
              showConfirmButton: false,
              timer: 1500
            })
            console.log(`berhasil update ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log('error: ', error);
            Swal.fire({
              icon: 'error',
              title: "Failed update the data.",
              showConfirmButton: false,
              timer: 1500
            })
          })
          .finally(() => {
            setIsLoading(false);
            fetchEditStock(id);
          })
      } else if (result.isDenied) {
        Swal.fire('Stock is not updated', '', 'info')
      }
    });

  }

  useEffect(() => {
    if (user.role === ADMIN) {
      fetchEditStock(id);
    };
  }, [token]);
  

  // VIEW

  const headerView = () => (
    <div className='page-header'>
      <div className='row align-items-center'>
        {/* Title */}
        <div className='col'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item Navbar_title'>Home / Edit Stock</li>
          </ol>
        </div>
      </div>
    </div>
  );

  const editView = () => (
    <div className='row row-deck row-cards'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>

              {/* Item Name */}
              <div className='col-md-4 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Item Name
                </label>
                <input
                  className="form-control"
                  defaultValue={data[0].item}
                  onChange={e => setData([{...data[0], item: e.target.value}])}
                />
              </div>

              {/* Amount */}
              <div className='col-md-4 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Value Item
                </label>
                <input
                  className="form-control"
                  defaultValue={data[0].value}
                  onChange={e => setData([{...data[0], value: e.target.value}])}
                />
              </div>

              {/* Stock */}
              <div className='col-md-4 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Amount
                </label>
                <input
                  className="form-control"
                  defaultValue={data[0].amount}
                  onChange={e => setData([{...data[0], amount: e.target.value}])}
                />
              </div>

              <div className='col-md-12'>
                <button
                  disabled={data.item !== "" && data.amount !== "" ? false : true}
                  type="button"
                  className='btn btn-danger col-md-12'
                  onClick={() => updateStock(id)}
                >
                  Save
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )

  return (
    <div className='container-fluid'>
      
      {headerView()}
      
      {isLoading ? <LoadingView /> : editView()}


    </div>
  );

}

export default EditStock;