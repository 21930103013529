import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';

import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';
import { HRTable, SelectColumnFilter } from '../../components/HRTable';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import { baseAPI } from '../../services/baseAPI';

function Employee() {

  //Roles
  const ADMIN = 1;
  const EMPLOYEE = 2;
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);

  const [modalAction, setModalAction] = useState(false)
  const [eligibleEmp, setEligibleEmp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  // CONTROLLER & LOAD DATA
  const fetchEligibleEmp = useCallback(async () => {

    await baseAPI
      .get('/v1/eligibleemp', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data.data.eligible_emp
        setEligibleEmp(data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(`error ${error}`);
      })
  }, [])

  // Put update claim status to API
  const updateClaimStatus = useCallback (async (id, npp) => {
    setIsLoading(true);
    const body = new FormData();

    await baseAPI
      .put(`/v1/eligibleemp/${npp}/${id}`, body, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: 'success',
          title: "Employee's claim is successful.",
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error: ', error);
        Swal.fire({
          icon: 'error',
          title: "Failed claim.",
          showConfirmButton: false,
          timer: 1500
        })
      })
      .finally(() => {
        setIsLoading(false);
        fetchEligibleEmp();
      })
  })

  useEffect(() => {
    setIsLoading(true);
    // console.log(users.role);
    if (users.role === ADMIN) {
      fetchEligibleEmp();
    }
  }, [users, token]);

  useEffect(() => {
    let timer;
    if (users.role === ADMIN) {
      timer = setTimeout(() => {
        if (count === 60) {
          fetchEligibleEmp();
          setCount(0);
        } else {
          setCount(count + 1);
        }
      }, 1000);
    }

    // will unmount
    return () => {
      if (users.role === ADMIN) {
        clearTimeout(timer);
      }
    };
  });


  // TABLE DESIGN
  const contentTableClaim = (id, npp, name, expiredate, status) => {
    if (id && name && npp && expiredate && status !== null) {
      return(
      <div className='text-center'>
        <button 
          type="button" 
          className={status === 0 || moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === false ? "btn btn-grey" : "btn btn-warning"}
          style={status === 0 || moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === false ? {backgroundColor: '#B1B3B3'} : {backgroundColor: '#EFB661'}}
          onClick={() => updateClaimStatus(id, npp)}
          disabled={status === 0 || moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === false ? true : false}
        >
          {status === 0 || moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === false ? "Claimed" : "Claim"}
        </button>
      </div>
      );
    }

    return <span>-</span>;
  };

  const contentTableAction = (id, npp) => {
    if (id && npp !== null) {
      return (
        <div className='text-center'>
          <Link 
            to={`/employee/editemployee/${id}/${npp}`} 
            className="btn btn-primary"
            style={{ backgroundColor: "#908CC2" }}
          >
            <Icon name="Edit" size={16} />
          </Link>
        </div>
      )
    }
  };

  const contenTableExpired = (expiredate, claimdate, claimstatus) => {
    let res;
    if (moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === false && claimstatus === 1) {
      res = 'Expired'
    } else if (moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === true && claimstatus === 1) {
      res = "-"
    } else if (moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === false && claimstatus === 0) {
      res = moment(claimdate).format("DD/MM/yyyy");
    } else if (moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === true && claimstatus === 0) {
      res = moment(claimdate).format("DD/MM/yyyy");
    } else if (moment(expiredate, "DD/MM/YYYY").isAfter(moment().format()) === false && claimstatus === 0 && claimdate === null) {
      res = "-"
    }
    return res;
  }

  const adminListView = () => {
    const columns = [
      {
        Header: 'No',
        id: 'row',
        width: '2%',
        accessor: 'no',
        sortType: 'basic',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.index + 1}</div>,
      },
      {
        Header: 'NPP',
        id: 'npp',
        width: '5%',
        accessor: 'npp',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.npp}</div>
      },
      {
        Header: 'Name',
        id: 'name',
        width: '12%',
        accessor: 'name',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.name}</div>
      },
      {
        Header: 'Department',
        id: 'department',
        width: '12%',
        accessor: 'department',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.department}</div>
      },
      {
        Header: 'Event',
        id: 'event',
        width: '8%',
        accessor: 'event',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.event}</div>
      },
      {
        Header: 'Item',
        id: 'item',
        width: '5%',
        accessor: 'item',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.item}</div>
      },
      {
        Header: 'Amount',
        id: 'amount',
        width: '5%',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.item_amount}</div>
      },
      {
        Header: 'Expire',
        id: 'expire',
        width: '8%',
        accessor: 'expire',
        sortType: 'datetime',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.expired_date}</div>
      },
      {
        Header: 'Claim Status',
        id: 'status',
        width: '5%',
        accessor: 'claim_status',
        sortType: 'basic',
        disableFilters: true,
        Cell: (cellInfo) => 
          contentTableClaim(
            cellInfo.row.original.id,
            cellInfo.row.original.npp,
            cellInfo.row.original.name,
            cellInfo.row.original.expired_date,
            cellInfo.row.original.claim_status
          )
      },
      {
        Header: 'Claimed Date',
        id: 'claimdate',
        width: '5%',
        accessor: 'claimed_date',
        disableFilters: true,
        Cell: (cellInfo) => contenTableExpired(
          cellInfo.row.original.expired_date, 
          cellInfo.row.original.claimed_at,
          cellInfo.row.original.claim_status,
        )
      },
      {
        Header: 'Action',
        id: 'action',
        width: '8%',
        Cell: (cellInfo) =>
          contentTableAction(
            cellInfo.row.original.id,
            cellInfo.row.original.npp,
          )
      }
    ];

    return (
      <>
        {/* Main card */}
        <div className='row row-deck row-cards'>
          {/* <div className='col-md-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-2'>
                    <button type='button' className='btn btn-danger col-md-12'>
                        <Icon name="Plus" size={17} /> &nbsp;
                        New Data
                    </button>
                  </div>
                  <div className='col-md-9'>
                    <input type='text' value="" className='form-control' placeholder='Search...' />
                  </div>
                  <div className='col-md-1'>
                    <button type='button' className='btn btn-danger col-md-12'>
                        <Icon name="Search" size={17} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-md-12">
            <Card styleBody={{ padding: 0, paddingTop: 20 }}>
              <HRTable
                title="Employee"
                columns={columns}
                data={eligibleEmp}
                formOne={null}
                formTwo={null}
                formThree={null}
                rowSize={[50, 100, 150]}
              />
            </Card>
          </div>

        </div>
      </>
    )
  };

  // VIEW
  const headerView = () => (
    <>
      <div className='page-header'>
        <div className='row align-items-center'>
          {/* Title */}
          <div className='col'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item Navbar_title'>Employee</li>
            </ol>
          </div>

          {/* Download Report Data */}
          <div className='col-auto ml-auto d-print-none'>
            <Link to="/employee/reportemployee">
              <button
                type="button"
                className="btn btn-gray"
                style={{ marginLeft: 10 }}
                onClick={() => console.log('download report data')}
              >
                <div className="d-flex align-items-center">
                  <Icon name="Download" size={16} style={{ marginRight: 5 }} />{' '}
                  <span>Download Report</span>
                </div>
              </button>
            </Link>
          </div>

          {/* Add New Data */}
          <div className='col-auto ml-auto d-print-none'>
            <Link to="/employee/addemployee">
              <button
                type="button"
                className="btn btn-gray"
                style={{ marginLeft: 10 }}
                onClick={() => console.log('new data')}
              >
                <div className="d-flex align-items-center">
                  <Icon name="PlusCircle" size={16} style={{ marginRight: 5 }} />{' '}
                  <span>Tambah Data Baru</span>
                </div>
              </button>
            </Link>
          </div>

          {/* Add New Data */}
          <div className='col-auto ml-auto d-print-none'>
            <Link to="/employee/addmanual">
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginLeft: 10 }}
                onClick={() => console.log('manual data')}
              >
                <div className="d-flex align-items-center">
                  <Icon name="PlusCircle" size={16} style={{ marginRight: 5 }} />{' '}
                  <span>Tambah Data Manual</span>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )

  const handleView = () => {
    switch (users.role) {
      case ADMIN:
        return adminListView();
    }
  };

   return (
    <div className='container-fluid'>

      {headerView()}

      {/* main content */}
      {isLoading ? <LoadingView /> : handleView()}

    </div>

  );

}

export default Employee;