// import { report } from 'process';
import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import useUserStore from '../../stores/Users';

import Icon from '../../components/Icon';

function Login() {

  const {login, validationReport} = useUserStore((state) => state);
  const [userName, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPass, setShowPass] = useState();
  const [loading, setLoading] = useState(false);

  // CONTROLLER
  const handleLogin = (e) => {
    console.log(validationReport);
    e.preventDefault();
    setLoading(true);
    const auth = login(userName, password);
    // console.log(auth);
    if (auth || !auth) {
      setLoading(false)
    }
  };

  const handleReport = (report) => {
    let res;
    if (report === null) {
      res = '';
    } else if (report.name === 'AxiosError') {
      res = 'Username atau password yang anda masukan salah.'
    } else {
      res = '';
    }
    return res;
  }

  // VIEW
  return (
    <div className='container'>
      <div className='row' style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
        }}>
        <div className='col-lg-3 col-md-auto'>
          <div className='card border-light mb-3' style={{boxShadow: '1px 2px 9px #D2D2D2'}}>
            <div className='card-body text-dark'>
              <p style={{fontSize: 20, fontWeight: 'bold', textAlign:'center'}}>HR PERIPHERAL</p>
              <form>

                {/* Username */}
                <div className='mb-3'>
                  <label for="username" className='form-label'>Username</label>
                  <input 
                    className='form-control' 
                    id="username" 
                    style={{backgroundColor: '#f3f4f6'}}
                    // placeholder="Enter Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                {/* Password */}
                <div className="mb-2">
                  <label htmlFor="true" className="form-label">
                    Password
                    {/* <span className='form-label-description'>
                      <a href='#forgot'>Lupa password</a>
                    </span> */}
                  </label>
                  <div className="input-group input-group-flat">
                    <input
                      type={showPass ? 'text' : 'password'}
                      hidden={false}
                      className="form-control"
                      style={{backgroundColor: '#f3f4f6'}}
                      // placeholder="Password"
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="btn input-group-text"
                      onClick={() => setShowPass(!showPass)}
                      aria-hidden="true"
                    >
                      <Icon name={showPass ? 'EyeOff' : 'Eye'} size={16} style={{ marginLeft: 15 }} />
                    </span>
                  </div>
                </div>

                {/* Password */}
                <div className="mb-2">
                  <label htmlFor="true" className="form-label text-red">{handleReport(validationReport)}</label>
                </div>
                
                {/* Button Sign In */}
                <div className="mb-3 mt-3">
                  {/* <Link to="/home" className="dropdown-item"> */}
                    <button
                      type="submit"
                      className="btn btn-danger w-100"
                      style={{backgroundColor: '#CE1126'}}
                      onClick={(e) => handleLogin(e)}
                    >
                      Sign In
                    </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Login;