import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import useUserStore from './stores/Users';

// Component
import Header from './components/Header';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import LoadingView from './components/Loading';

// Pages Home
import Home from './pages/home/Home';
import EditStock from './pages/home/EditStock';

// Pages Employee
import Employee from './pages/employee/Employee';
import AddEmployee from './pages/employee/AddEmployee';
import AddManual from './pages/employee/AddManual';
import ReportEmployee from './pages/employee/ReportEmployee';
import EditEmployee from './pages/employee/EditEmployee';

// Pages Scan
import Scan from './pages/scan/Scan';

// Auth
import Login from './pages/auth/Login';

function App() {

  // roles
  const ADMIN = 1;
  const EMPLOYEE = 2;
  const loading = JSON.parse(useUserStore((state) => state.loading));
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);
  const refreshSession = useUserStore((state) => state.refreshSession);
  let dataShow;

  // refresh
  useEffect(() => {
    refreshSession();
  }, [refreshSession]);
  

  const hasAuthView = () => (
    <div className='page'>
      {/* <Header /> */}
      <Navbar />
      <div className='content'>
        <ScrollToTop />
          <Switch>
            {users.role === ADMIN ?
            <>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/editstock/:id/:detailid">
                <EditStock />
              </Route>
              <Route exact path="/employee">
                <Employee />
              </Route>
              <Route exact path="/scan">
                <Scan />
              </Route>
              <Route exact path="/employee/addemployee">
                <AddEmployee />
              </Route>
              <Route exact path="/employee/addmanual">
                <AddManual />
              </Route>
              <Route exact path="/employee/reportemployee">
                <ReportEmployee />
              </Route>
              <Route exact path="/employee/editemployee/:id/:npp">
                <EditEmployee />
              </Route>
            </>
            :
            <>
              <Route exact path="/myvoucher">
                <Scan />
              </Route>
              <Redirect to="/myvoucher" />
            </>
            }
            
          </Switch>
      </div>
    </div>
  )

  const authView = () => (
    // <div className='container'>
        <Switch>
          <Route exact path="/">
            <Header />
            <Login />
          </Route>
          <Redirect to="/" />
        </Switch>
    // </div>
  );

  if (loading) {
    dataShow = <LoadingView />;
  } else if (token) {
    dataShow = hasAuthView();
  } else {
    dataShow = authView();
  }
  
  return dataShow;

}

export default App;
