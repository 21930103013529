import React, { useCallback, useState, useEffect, useRef } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import moment from 'moment';
import Swal from 'sweetalert2';

import Icon from '../../components/Icon';
import LoadingView from '../../components/Loading';
import { HRTable, SelectColumnFilter } from '../../components/HRTable';
import Card from '../../components/SimpleCard';
import useUserStore from '../../stores/Users';
import { baseAPI } from '../../services/baseAPI';

function Scan() {

  const ADMIN = 1;
  const EMPLOYEE = 2;
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [code, setCode] = useState();
  const [tempCode, setTempCode] = useState();
  const [data, setData] = useState([]);
  const [statusClaim, setStatusClaim] = useState();
  const scanCode = useRef(null);

  // CONTROLLER
  // Logic dynamic change from Input field after scan barcode
  // Logic call data after scan barcode
  const handleChange = useCallback (async (code, id) => {
    
    if (code !== undefined) {
      const strCode = code.split("")
      scanCode.current.value = code;  
      if (strCode.length === 8) {  
        setTempCode(code)
        setIsLoading(true)
        await baseAPI
          .get(`/v1/eligibleemp/${code}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            const data = res.data.data.eligible_emp;
            if (data.length > 0) {
              Swal.fire({
                // position: 'top-end',
                icon: 'success',
                title: "Yeay, let's claim your item!",
                showConfirmButton: false,
                timer: 1500
              });
              scanCode.current.value = '';
            } else {
              Swal.fire({
                // position: 'top-end',
                icon: 'error',
                title: "You don't have any item.",
                showConfirmButton: false,
                timer: 1500
              })
            }
            setData(data);
            setIsLoading(false);
          })
      }
    }

  }, [])

  // Put update claim status to API
  const updateClaimStatus = useCallback (async (id, npp) => {
    setIsLoading(true);
    const body = new FormData();
    
    await baseAPI
      .put(`/v1/eligibleemp/${npp}/${id}`, body, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: 'success',
          title: "Your claim is successful.",
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error: ', error);
        Swal.fire({
          icon: 'error',
          title: "Your claim failed",
          showConfirmButton: false,
          timer: 1500
        })
      })
      .finally(() => {
        setIsLoading(false);
        handleChange(npp, id);
      })
  })

  useEffect(() => {
    // setIsLoading(true);
    if (users.role === ADMIN) {
      handleChange();
    }
  }, [users.token])
  

  useEffect(() => {
    let timer;
    if (users.role === ADMIN) {
      timer = setTimeout(() => {
        // console.log(count);
        if (count === 8) {
          scanCode.current.value = '';
          scanCode.current.focus();
          setCount(0);
        } else {
          setCount(count + 1);
        }
      }, 1000);
    }

    // will unmount
    return () => {
      if (users.role === ADMIN) {
        clearTimeout(timer);
      }
    };
  });

   // VIEW

  const headerView = () => (
    <>
      <div className='page-header'>
        <div className='row align-items-center'>
          {/* Title */}
          <div className='col'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item Navbar_title'>Scan</li>
            </ol>
          </div>
        </div>
      </div>
    </>
  )

  const chooseView = () => (
    <div className='row row-deck row-cards'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-3'>
                <h3>NPP</h3>
                <input 
                  type='text' 
                  ref={scanCode} 
                  className='form-control' 
                  onChange={(e) => handleChange(e.target.value)} 
                  autoFocus={scanCode !== '' ? true : true}
                />
              </div>
              {/* <p>{tempCode}</p> */}
              <BarcodeScannerComponent
                width={5}
                height={5}
                onUpdate={(err, result) => {
                  if (result) setCode(result.text);
                  else setCode("Not Found");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const contentTableAction = (id, npp, name, expiredate, status) => {
    if (id && name && npp && expiredate && status !== null) {
      return(
      <div className='text-center'>
        <button 
          type="button" 
          className={status === 1 ? "btn btn-warning" : "btn btn-grey"}
          style={status === 1 ? {backgroundColor: '#EFB661'} : {backgroundColor: '#B1B3B3'}}
          onClick={() => updateClaimStatus(id, npp)}
          disabled={users.role === ADMIN ? false : true}
        >
          {status === 1 ? "Claim" : "Claimed"}
        </button>
      </div>
      );
    }

    return <span>-</span>;
  }

  const mainView = () => {
    const columns = [
      {
        Header: 'No',
        id: 'row',
        width: '3%',
        accessor: 'no',
        sortType: 'basic',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.index + 1}</div>,
      },
      {
        Header: 'NPP',
        id: 'npp',
        width: '8%',
        accessor: 'npp',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.npp}</div>
      },
      {
        Header: 'Name',
        id: 'name',
        width: '12%',
        accessor: 'name',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.name}</div>
      },
      {
        Header: 'Department',
        id: 'department',
        width: '15%',
        accessor: 'department',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.department}</div>
      },
      {
        Header: 'Event',
        id: 'event',
        width: '10%',
        accessor: 'event',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.event}</div>
      },
      {
        Header: 'Item',
        id: 'item',
        width: '5%',
        accessor: 'item',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.item}</div>
      },
      {
        Header: 'Expire Date',
        id: 'expire',
        width: '5%',
        accessor: 'expired_date',
        sortType: 'datetime',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.expired_date}</div>
      },
      {
        Header: 'Claim Status',
        id: 'status',
        width: '5%',
        accessor: 'claim_status',
        sortType: 'basic',
        disableFilters: true,
        Cell: (cellInfo) => 
          contentTableAction(
            cellInfo.row.original.id,
            cellInfo.row.original.npp,
            cellInfo.row.original.name,
            cellInfo.row.original.expired_date,
            cellInfo.row.original.claim_status
          )
      },
      {
        Header: 'Claimed Date',
        id: 'claimdate',
        width: '5%',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.claimed_at === null ? "-" : moment(cellInfo.row.original.claimed_at).format("DD/MM/yyyy")}</div>
      },
    ];

    return (
      <>
        {/* Main card */}
        <div className='row mt-2 row-deck row-cards'>
          <div className="col-md-12">
            <Card styleBody={{ padding: 0, paddingTop: 20 }}>
              <HRTable
                title="Employee"
                columns={columns}
                data={data}
                formOne={null}
                formTwo={null}
                formThree={null}
                rowSize={[50, 100, 150]}
              />
            </Card>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className='container-fluid'>
      {headerView()}
      {chooseView()}
      {isLoading ? <LoadingView /> : mainView()}
    </div>

  );

}

export default Scan;