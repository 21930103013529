import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useUserStore from '../stores/Users';
import Icon from './Icon';
import './Navbar.css';

function Navbar() {

  // Roles
  const ADMIN = 1;
  const EMPLOYEE = 2;
  const user = useUserStore((state) => state.user);
  const logout = useUserStore((state) => state.logout);

  const Linked = ({ to, title, children }) => {
    const location = useLocation();
    const pathName = location.pathname.split('/')[1];
    return (
      <li
        className={
          to === `/${pathName}` ? 'nav-item Navbar_item Navbar_active' : 'nav-item Navbar_item'
        }
        // className='nav-item Navbar_item Navbar_active'
      >
        <Link className="nav-link Navbar_nav-link" to={to}>
          <span className='nav-link-icon Navbar_icon d-md-none d-lg-inline-block'>{children}</span>
          <span className='Navbar_title'>{title}</span>
        </Link>
      </li>
    );
  };

  Linked.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout(null);
  };

  // VIEW
  return (
    <div className='sticky-top'>
      {/* header */}
      <header className="navbar navbar-expand-md navbar-dark Navbar_dark sticky-top d-print-none">
        <div className="container-fluid">
          {/* leftside */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <h5 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3" style={{fontSize: 20}}>
            Collins Aerospace
          </h5>

          {/* rightside */}
          <div className="navbar-nav flex-row order-md-last">
            {/* notifikasi */}
            {/* <div className='nav-item Navbar_nav-item dropdown d-none d-md-flex me-3'>
              <Link
                className='nav-link Navbar_nav-link px-0'
                data-bs-toggle='dropdown'
                tabindex='-1'
                aria-label='Show notifications'>
                <Icon name='Bell' size={17} color={"#fff"} />
                <span className='badge bg-red'></span>
              </Link>
              <div className='dropdown-menu dropdown-menu-end dropdown-menu-card'>
                <div className='card'>
                  <div className='card-body'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad amet
                    consectetur exercitationem fugiat in ipsa ipsum, natus odio quidem quod
                    repudiandae sapiente. Amet debitis et magni maxime necessitatibus ullam.
                  </div>
                </div>
              </div>
            </div> */}

            {/* profile */}
            <div className="nav-item Navbar_nav-item dropdown">
              <a
                href="#user"
                className="nav-link Navbar_nav-link d-flex lh-1 text-reset p-0"
                data-bs-toggle="dropdown"
                aria-label="Open user menu"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/avatar.jpg`}
                  className="avatar Navbar_avatar avatar-sm"
                  alt="users"
                />
                <div className="d-none d-xl-block ps-2">
                  <div className="username">
                    <strong>
                      {/* HR */}
                      {user.name}
                    </strong>
                  </div>
                  {/* <div className="mt-1 small">{user.email}</div> */}
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                {/* <Link to='/profile' className='dropdown-item'>Set status</Link>
                <Link to='/profile' className='dropdown-item'>Profile & account</Link>
                <Link to='/profile' className='dropdown-item'>Feedback</Link> */}
                {/* <Link to="/password" className="dropdown-item">
                  Ubah Password
                </Link> */}
                <a 
                  href="#handleLogout" 
                  onClick={(e) => handleLogout(e)} 
                  className="dropdown-item"
                >
                    Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* menu */}
      <div className="navbar-expand-md">
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div className="navbar navbar-light">
            <div className="container-fluid">
              <ul className="navbar-nav">

                {/* home */}
                {user.role === ADMIN ?
                <Linked to="/" title="Home">
                  <Icon name="Home" size={17} />
                </Linked>
                : <></>}

                {/* eligible employee */}
                {user.role === ADMIN ?
                <Linked to="/employee" title='Employee'>
                  <Icon name="Users" size={17} />
                </Linked>
                : <></>}

                {/* scan */}
                {user.role === ADMIN ?
                <Linked to="/scan" title="Scan">
                  <Icon name="Speaker" size={17} />
                </Linked>
                : <></>}

                {/* my voucher */}
                {user.role === EMPLOYEE ?
                <Linked to="/myvoucher" title="My Voucher">
                  <Icon name="Heart" size={17} />
                </Linked>
                : <></>}


              </ul>

              {/* search bar */}
              {/* <div className='my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last'>
                <form action='.' method='get'>
                  <div className='input-icon'>
                    <span className='input-icon-addon'>
                      <Icon name='Search' size={17} />
                    </span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search…'
                      aria-label='Search in website'
                    />
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Navbar;
