import React, { useEffect, useState, useCallback, Component } from 'react';
import { Link } from 'react-router-dom';
import useUserStore from '../../stores/Users';

// Component
import LoadingView from '../../components/Loading';
import Icon from '../../components/Icon';

// API
import { baseAPI } from '../../services/baseAPI';
import axios from 'axios';


function Home() {

  // Roles
  const ADMIN = 1;
  const EMPLOYEE = 2;
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);

  const [stockData, setStockData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [count, setCount] = useState(0);

  // CONTROLLER & LOAD DATA
  const fetchStock = useCallback(async () => {
    await baseAPI
      .get(`/v1/stock`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data.data.stock;
        // console.log(data);
        setStockData(data);
        console.log(`berhasil mengambil data ${JSON.stringify(res)}`);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(`error ${error}`);
      })
  }, [])

  useEffect(() => {
    setIsLoading(true);
    // console.log(users.role);
    if (users.role === ADMIN) {
      fetchStock();
    }
  }, [users, token]);

  useEffect(() => {
    let timer;
    if (users.role === ADMIN) {
      timer = setTimeout(() => {
        if (count === 60) {
          fetchStock();
          setCount(0);
        } else {
          setCount(count + 1);
        }
      }, 1000);
    }

    // will unmount
    return () => {
      if (users.role === ADMIN) {
        clearTimeout(timer);
      }
    };
  });

  // VIEW

  const homeView = () => (
    <>
    <div className='row row-deck row-cards'>     
      {/* Card item */}
      {stockData.map(e => (
        <div className='col-md-6' key={e.id}>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <span className='badge bg-azure' style={{fontSize:12, backgroundColor: '#EFB661'}}>January</span>
                </div>
                <div className='col-auto ml-auto d-print-none'>
                  <Link 
                    to={`/editstock/${e.id}/${e.detail_id}`} 
                    className="btn btn-primary"
                  >
                    <Icon name="Edit" size={16} />
                  </Link>
                </div>
              </div>
              <div className='row' style={{textAlign:'center', justifyContent:'center'}}>
                <div className='col-md-6'>
                  <h1 style={{fontSize: 70}}>{e.amount}</h1>
                  <h1 style={{fontWeight:'bold', fontSize: 40, marginBottom: 10, color:'#7BA7BC'}}>{e.item + " " + e.value}</h1>
                </div>
                <div className='col-md-6'>
                  {/* <p>Gambar</p> */}
                  <img
                    src={`${process.env.PUBLIC_URL}/static/voucher.png`}
                    style={{width: 180, height: 180}}
                    // className="avatar Navbar_avatar avatar-sm"
                    // alt="users"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    </>
  )

  return (
    <div className='container-fluid'>
      <div className='page-header'>
        <div className='row align-items-center'>
          <div className='col'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item Navbar_title'>Home</li>
            </ol>
          </div>
        </div>
      </div>
      {/* content */}
      {isLoading ? <LoadingView /> : homeView()}
    </div>

  );
}

export default Home;