import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import axios from 'axios';
import { baseAPI } from '../../services/baseAPI';

function EditEmployee() {
  const { id, npp } = useParams();
  const history = useHistory();
  const ADMIN = 1;
  const token = useUserStore((state) => state.token);
  const user = useUserStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState({
    name: "",
    npp: "",
    department:"",
    event: "",
    item_id: "",
    item_amount: "",
    event_date: "",
    expired_date: "",
    claim_status: "",
    claimed_at: "",
  });
  const [listStatus, setListStatus] = useState([
    { label: 'Claim', value: 1 },
    { label: 'Claimed', value: 0 },
  ]);
  const [itemList, setItemList] = useState([
    { label: "Chocolate", value: "Chocolate" },
    { label: "Voucher", value: "Voucher" }
  ])
  const [empStatus, setEmpStatus] = useState([]);
  const [empItem, setEmpItem] = useState([]);


  // CONTROLLER

  const fetchEditEmployee = async (id) => {
    setIsLoading(true);
    await axios
      .all(
        [
        baseAPI .get(`/v1/eligibleemp/edit/${id}`, { headers: { Authorization: `Bearer ${token}` } }),
        baseAPI .get(`/v1/stock`, { headers: { Authorization: `Bearer ${token}` } }),
        ]
      )
      .then((res) => {
        const stock = res[1].data.data.stock;
        const data = res[0].data.data.update;
        if (data.length > 0) {
          // Swal.fire({
          //   icon: 'success',
          //   title: `Berhasil mengambil data`,
          //   showConfirmButton: false,
          //   timer: 1500
          // });

          let claim = [];
          if (data[0].claim_status === 0) {
            claim.push({ label: "Claimed", value: 0 })
          } else {
            claim.push({ label: "Claim", value: 1 })
          };

          let item = [];
          for (let i = 0; i < stock.length; i+=1) {
            if (data[0].item_id === stock[i].id) {
              item.push({ label: stock[i].item, value: stock[i].id })
            }
          };
          
          setEmpItem(item);
          setEmpStatus(claim);
          setNewData([{ 
            name: data[0].name,
            npp: data[0].npp,
            department: data[0].department,
            event: data[0].event,
            item_id: data[0].item_id,
            item_amount: data[0].item_amount,
            event_date: data[0].event_date,
            claim_status: data[0].claim_status,
            claimed_at: data[0].claimed_at,
          }])
          setData(data[0]);
        } else {
          Swal.fire({
            icon: 'error',
            title: `Data tidak tersedia.`,
            showConfirmButton: false,
            timer: 1500
          })
        };

        let stockData = []
        stock.forEach(e => {
          stockData.push({ label: e.item, value: e.id })
        });
        setItemList(stockData);

      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: 'error',
          title: `Gagal mengambil data`,
          showConfirmButton: false,
          timer: 1500
        })
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  const updateEligibleEmp = async (id) => {
    
    Swal.fire({
      title: 'Do you want to update the data?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const body = new FormData();
        body.append('update_data', JSON.stringify(newData));
        // console.log(body.get('update_data'))
        baseAPI
          .put(`/v1/eligibleemp/update/${id}/${npp}`, body, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: "Eligible Employee data has been updated.",
              showConfirmButton: false,
              timer: 1500
            })
            console.log(`berhasil update ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log('error: ', error);
            Swal.fire({
              icon: 'error',
              title: "Failed update the data.",
              showConfirmButton: false,
              timer: 1500
            })
          })
          .finally(() => {
            setIsLoading(false);
            fetchEditEmployee(id);
          })
      } else if (result.isDenied) {
        Swal.fire('Data is not updated', '', 'info')
      }
    });

  }

  useEffect(() => {
    if (user.role === 1) {
      fetchEditEmployee(id);
    };
  }, [token]);
  

  // VIEW

  const headerView = () => (
    <div className='page-header'>
      <div className='row align-items-center'>
        {/* Title */}
        <div className='col'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item Navbar_title'>Employee / Edit Employee</li>
          </ol>
        </div>
      </div>
    </div>
  );

  const editView = () => (
    <div className='row row-deck row-cards'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>

              {/* Name */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Name
                </label>
                <input
                  className="form-control"
                  defaultValue={data.name}
                  disabled
                />
              </div>

              {/* NPP */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  NPP
                </label>
                <input
                  className="form-control"
                  defaultValue={data.npp}
                  disabled
                />
              </div>

              {/* Department */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Department
                </label>
                <input
                  className="form-control"
                  defaultValue={data.department}
                  disabled
                />
              </div>

              {/* Event */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Event
                </label>
                <input
                  defaultValue={data.event}
                  onChange={e => setNewData([{...newData[0], event: e.target.value}])}
                  type="text"
                  className="form-control"
                />
              </div>

              {/* Item */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Item
                </label>
                <Select
                  options={itemList}
                  defaultValue={itemList && empItem}
                  placeholder="Pilih status"
                  onChange={e => setNewData([{...newData[0], item_id: e.value}])}
                />
              </div>

              {/* Item Amount */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Amount
                </label>
                <input
                  defaultValue={data.item_amount}
                  onChange={e => setNewData([{...newData[0], item_amount: e.target.value}])}
                  type="text"
                  className="form-control"
                />
              </div>

              {/* Event Date */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Event Date
                </label>
                  <DatePicker
                    selected={data.event_date !== null ? moment(data.event_date, "DD/MM/YYYY").utcOffset(7, true).toDate() : false}
                    placeholderText="Pilih tanggal"
                    className="form-control datepicker"
                    onChange={date => {
                      setNewData([{...newData[0], event_date: moment(date).format('DD/MM/YYYY')}])
                      setData({...data, event_date: date})
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
              </div>

              {/* Expired Date */}
              {/* <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Expired Date
                </label>
                  <DatePicker
                    selected={moment(data.expired_date).utcOffset(7, true).toDate()}
                    className="form-control datepicker"
                    onChange={date => {
                      setNewData([{...newData[0], expired_date: moment(date).format('MM/DD/yyyy')}])
                      setData({...data, expired_date: date})
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
              </div> */}

              {/* Claim Status */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Claim Status
                </label>
                  <Select
                    options={listStatus}
                    defaultValue={listStatus && empStatus}
                    placeholder="Pilih status"
                    onChange={e => setNewData([{...newData[0], claim_status: e.value}])}
                  />
              </div>

              <div className='col-md-12'>
                {/* <label htmlFor='true' className='form-label' style={{color: "white"}}>
                   ,,,
                </label> */}
                <button
                  disabled={newData.event !== "" && newData.item_id !== "" && newData.amount !== "" && data.event_date !== null && data.expired_date !== null ? false : true}
                  type="button"
                  className='btn btn-danger col-md-12'
                  onClick={() => updateEligibleEmp(id)}
                >
                  Save
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )

  return (
    <div className='container-fluid'>
      
      {headerView()}
      
      {isLoading ? <LoadingView /> : editView()}


    </div>
  );

}

export default EditEmployee;