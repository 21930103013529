import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useForm } from 'react-hook-form';

import useUserStore from '../../stores/Users';
import Icon from '../../components/Icon';
import LoadingView from '../../components/Loading';
import { HRTable, SelectColumnFilter } from '../../components/HRTable';
import Card from '../../components/SimpleCard';
import { baseAPI } from '../../services/baseAPI';
import * as XLSX from 'xlsx';

function ReportEmployee() {
  // State
  const ADMIN = 1;
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [timeList, setTimeList] = useState([
    { name: "month", value: "1/2023", label: "January 2023" },
    { name: "month", value: "2/2023", label: "February 2023" },
    { name: "month", value: "3/2023", label: "March 2023" },
    { name: "month", value: "4/2023", label: "April 2023" },
    { name: "month", value: "5/2023", label: "May 2023" },
    { name: "month", value: "6/2023", label: "June 2023" },
    { name: "month", value: "7/2023", label: "July 2023" },
    { name: "month", value: "8/2023", label: "August 2023" },
    { name: "month", value: "9/2023", label: "September 2023" },
    { name: "month", value: "10/2023", label: "October 2023" },
    { name: "month", value: "11/2023", label: "November 2023" },
    { name: "month", value: "12/2023", label: "December 2023" },
  ]);


  // Controller

  const fetchEligibleEmp = useCallback (async () => {
    await baseAPI
      .get('/v1/eligibleemp', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data.data.eligible_emp
        console.log(data);
        let finalObj = []
        data.forEach((e) => {
          if (e.claimed_at !== null) {
            const date = moment(e.claimed_at).format('MMMM YYYY')
            finalObj.push({ label: date, value:  moment(e.claimed_at).format('M/YYYY') });
          }
      })
      const listMonth = [...new Map(finalObj.map((m) => [m.value, m])).values()];
      setTimeList(listMonth);
      setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(`error ${error}`);
      });
  });

  const fetchReport = useCallback (async (params, month) => {
    setIsLoading(true)
    await baseAPI
      .get(`/v1/eligibleemp/report/${params}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data.data.report
        console.log(data);
        if (data.length > 0) {
          Swal.fire({
            icon: 'success',
            title: `Report Data ${month} berhasil diambil`,
            showConfirmButton: false,
            timer: 1500
          })
          setReportData(data)
        } else {
          Swal.fire({
            icon: 'error',
            title: `Data ${month} tidak tersedia.`,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: 'error',
          title: `Gagal mengambil data`,
          showConfirmButton: false,
          timer: 1500
        })
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  useEffect(() => {
    setIsLoading(true);
    // console.log(users.role);
    if (users.role === ADMIN) {
      fetchEligibleEmp();
    }
  }, [users, token]);


  const exportExcel = (data) => {
    setIsLoading(true)
    let finalHeaders = ['npp', 'name', 'department', 'event', 'item', 'amount', 'expired_date', 'claim_status', 'claimed_at'];
    let fixData = [];
    data.forEach((e, i) => 
      fixData.push({
        npp: e.npp,
        name: e.name,
        department: e.department,
        event: e.event,
        item: e.item,
        amount: e.item_amount,
        expired_date: e.expired_date,
        claim_status: e.claim_status,
        claimed_at: moment(e.claimed_at).format("DD/MM/YYYY")
      })
    );

    let ws = XLSX.utils.json_to_sheet(fixData, {header: finalHeaders});
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    let exportFileName = `HRPeripheralRecap_${moment(data[0].claimed_at).format('MMMM')}_${moment(data[0].claimed_at).format('YYYY')}.xls`;
    XLSX.writeFile(wb, exportFileName);
    setIsLoading(false);
  };


  // View
  const headerView = () => (
    <div className='page-header'>
      <div className='row align-items-center'>
        {/* Title */}
        <div className='col'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item Navbar_title'>Employee / Download Report</li>
          </ol>
        </div>
      </div>
    </div>
  );

  const chooseView = () => (
    <div className='row row-deck row-cards'>
      <div className='card'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-6'>
              <label htmlFor='true' className='form-label'>
                Choose Time
              </label>
              <Select
                options={timeList}
                placeholder="Choose time"
                name="time"
                onChange={(e) => fetchReport(e.value, e.label)}
              />
            </div>
            <div className='col-md-6'>
              <br />
                <button
                  type='button'
                  className='btn btn-danger col-md-6 offset-md-6'
                  onClick={(e) => exportExcel(reportData)}
                >
                  Download Data
                </button>
                {/* <ExportExcel excelData={excelData} fileName={"ExcelExport"} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Table Design
  const excelPreview = () => {
    const columns = [
      {
        Header: 'No',
        id: 'row',
        width: '3%',
        accessor: 'no',
        sortType: 'basic',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.index + 1}</div>,
      },
      {
        Header: 'NPP',
        id: 'npp',
        width: '8%',
        accessor: 'npp',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.npp}</div>
      },
      {
        Header: 'Name',
        id: 'name',
        width: '12%',
        accessor: 'name',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.name}</div>
      },
      {
        Header: 'Department',
        id: 'department',
        width: '15%',
        accessor: 'department',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.department}</div>
      },
      {
        Header: 'Event',
        id: 'event',
        width: '10%',
        accessor: 'event',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.event}</div>
      },
      {
        Header: 'Item',
        id: 'item',
        width: '5%',
        accessor: 'item',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.item}</div>
      },
      {
        Header: 'Amount',
        id: 'amount',
        width: '5%',
        accessor: 'amount',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.item_amount}</div>
      },
      {
        Header: 'Expired Date',
        id: 'expire',
        width: '5%',
        accessor: 'expired_date',
        sortType: 'datetime',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{moment(cellInfo.row.original.expired_date, "DD/MM/YYYY").format("DD/MM/YYYY")}</div>
      },
      {
        Header: 'Claim Status',
        id: 'claim_status',
        width: '5%',
        accessor: 'claim_status',
        sortType: 'basic',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.claim_status}</div>
      },
      {
        Header: 'Claim Date',
        id: 'claim_date',
        width: '5%',
        accessor: 'claim_date',
        sortType: 'datetime',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{moment(cellInfo.row.original.claimed_at).format("DD/MM/yyyy")}</div>
      },
    ];

    return (
      <>
        {/* Main card */}
        <div className='row mt-2 row-deck row-cards'>
            <Card styleBody={{ padding: 0, paddingTop: 20 }}>
              <HRTable
                title="Report Employee"
                columns={columns}
                data={reportData}
                formOne={null}
                formTwo={null}
                formThree={null}
                rowSize={[50, 100, 150]}
              />
            </Card>
        </div>
      </>
    )
  };

  return (
    <div className='container-fluid'>
      
      {headerView()}
      {chooseView()}

      {/* main content */}
      {isLoading ? <LoadingView /> : excelPreview()}
      
    </div>

  );

}

export default ReportEmployee;