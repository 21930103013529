import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import axios from 'axios';
import { baseAPI } from '../../services/baseAPI';

function AddManual() {
  const { id, npp } = useParams();
  const history = useHistory();
  const ADMIN = 1;
  const token = useUserStore((state) => state.token);
  const user = useUserStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [newData, setNewData] = useState([{
    name: "",
    npp: "",
    department:"",
    event: "",
    item: "",
    amount: "",
    event_date: moment().format("DD/MM/YYYY"),
    expired_date: "",
  }]);
  const [listStatus, setListStatus] = useState([
    { label: 'Claim', value: 1 },
    { label: 'Claimed', value: 0 },
  ]);
  const [departmentList, setDepartmentList] = useState([
    { label: 'CORE-IE', value: 'CORE-IE' },
    { label: 'EH&S AND FU', value: 'EH&S AND FU' },
    { label: 'ENGINEERING', value: 'ENGINEERING' },
    { label: 'FINANCE & IT', value: 'FINANCE & IT' },
    { label: 'HR', value: 'HR' },
    { label: 'MAINTENANCE', value: 'MAINTENANCE' },
    { label: 'MATERIAL AND PLANNING', value: 'MATERIAL AND PLANNING' },
    { label: 'OPERATIONS', value: 'OPERATIONS' },
    { label: 'PRODUCTION', value: 'PRODUCTION' },
    { label: 'QUALITY', value: 'QUALITY' },
  ])
  const [ itemList, setItemList] = useState();
  const [empItem, setEmpItem] = useState([]);
  const [empDept, setEmpDept] = useState([]);


  // CONTROLLER

  const fetchAddEmployee = async () => {
    setIsLoading(true);
    await baseAPI 
      .get(`/v1/stock`, {
         headers: { Authorization: `Bearer ${token}` } 
      })
      .then((res) => {
        const stock = res.data.data.stock;
        let stockData = []
        stock.forEach(e => {
          stockData.push({ label: e.item, value: e.detail_id })
        });         
        setItemList(stockData);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: 'error',
          title: `Gagal mengambil data`,
          showConfirmButton: false,
          timer: 1500
        })
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  const storeEligibleEmp = async () => {
    
    Swal.fire({
      title: 'Do you want to save the data?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        let fixData = [];
        const expireDate = moment(newData[0].event_date, 'DD/MM/YYYY').add(30, 'days');
        newData.push({...newData[0], expired_date: moment(expireDate._d).format("DD/MM/YYYY")});
        fixData.push(newData[1]);
        const body = new FormData();
        body.append('new_data', JSON.stringify(fixData));
        // console.log(body.get('new_data'))
        baseAPI
          .post(`/v1/eligibleemp`, body, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: "New eligible Employee has been added.",
              showConfirmButton: false,
              timer: 1500
            })
            console.log(`berhasil upload ${JSON.stringify(res)}`);
            setEmpItem([]);
            setEmpDept([]);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log('error: ', error);
            Swal.fire({
              icon: 'error',
              title: "Failed update the data.",
              showConfirmButton: false,
              timer: 1500
            })
          })
          .finally(() => {
            setIsLoading(false);
            fetchAddEmployee();
          })
      } else if (result.isDenied) {
        Swal.fire('Data is not updated', '', 'info')
      }
    });

  }

  useEffect(() => {
    if (user.role === ADMIN) {
      fetchAddEmployee();
    };
  }, [token]);
  

  // VIEW

  const headerView = () => (
    <div className='page-header'>
      <div className='row align-items-center'>
        {/* Title */}
        <div className='col'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item Navbar_title'>Employee / Add Manual Employee</li>
          </ol>
        </div>
      </div>
    </div>
  );

  const addView = () => (
    <div className='row row-deck row-cards'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>

              {/* Name */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Name
                </label>
                <input
                  placeholder='Employee name'
                  className="form-control"
                  onChange={e => setNewData([{...newData[0], name: e.target.value}])}
                />
              </div>

              {/* NPP */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  NPP
                </label>
                <input
                  placeholder='Employee NPP'
                  className="form-control"
                  onChange={e => setNewData([{...newData[0], npp: e.target.value}])}
                />
              </div>

              {/* Department */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Department
                </label>
                <Select
                  options={departmentList}
                  defaultValue={departmentList && empDept}
                  placeholder="Choose department"
                  onChange={e => {
                      setNewData([{...newData[0], department: e.value}])
                      setEmpDept(e);
                  }}
                />
              </div>

              {/* Event */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Event
                </label>
                <input
                  placeholder='Event name'
                  onChange={e => setNewData([{...newData[0], event: e.target.value}])}
                  type="text"
                  className="form-control"
                />
              </div>

              {/* Item */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Item
                </label>
                <Select
                  options={itemList}
                  defaultValue={itemList && empItem}
                  placeholder="Pilih item"
                  onChange={e => {
                    setNewData([{...newData[0], item: e.label}])
                    setEmpItem(e);
                  }}
                />
              </div>

              {/* Item Amount */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Amount
                </label>
                <input
                  placeholder='Item amount, exp: 100'
                  onChange={e => setNewData([{...newData[0], amount: e.target.value}])}
                  type="text"
                  className="form-control"
                />
              </div>

              {/* Event Date */}
              <div className='col-md-6 mb-3'>
                <label htmlFor='true' className='form-label'>
                  Event Date
                </label>
                  <DatePicker
                    selected={newData[0].event_date !== "" ? moment(newData[0].event_date, "DD/MM/YYYY").utcOffset(7, true).toDate() : moment().utcOffset(7, true).toDate()}
                    placeholderText="Pilih tanggal"
                    className="form-control datepicker"
                    onChange={date => setNewData([{...newData[0], event_date: date}])}
                    dateFormat="dd/MM/yyyy"
                  />
              </div>

              <div className='col-md-6'>
                <label htmlFor='true' className='form-label' style={{color: "white"}}>
                   ,,,
                </label>
                <button
                  disabled={newData[0].event !== "" && newData[0].item !== "" && newData[0].amount !== "" && newData[0].event_date !== "" && newData[0].name !== "" && newData[0].npp !== "" && newData[0].department !== "" ? false : true}
                  type="button"
                  className='btn btn-danger col-md-12'
                  onClick={() => storeEligibleEmp()}
                >
                  Save
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )

  return (
    <div className='container-fluid'>
      
      {headerView()}
      
      {isLoading ? <LoadingView /> : addView()}


    </div>
  );

}

export default AddManual;