import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { useForm } from 'react-hook-form';

import useUserStore from '../../stores/Users';
import Icon from '../../components/Icon';
import LoadingView from '../../components/Loading';
import { HRTable, SelectColumnFilter } from '../../components/HRTable';
import Card from '../../components/SimpleCard';
import { baseAPI } from '../../services/baseAPI';


function AddEmployee() {
  // State
  const ADMIN = 1;
  const EMPLOYEE = 2;
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState([]);
  const newFile = useRef(null);

  // Controller

  const storeNewData = (params) => {
    
    Swal.fire({
      title: 'Do you want to save the new data?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const body = new FormData();
        body.append('new_data', JSON.stringify(params));
        // console.log(body.get('new_data'))

        baseAPI
          .post('/v1/eligibleemp', body, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: "New Eligible Employee data has been saved.",
              showConfirmButton: false,
              timer: 1500
            })
            console.log(`berhasil upload ${JSON.stringify(res)}`);
            newFile.current.value = '';
          })
          .catch((error) => {
            setIsLoading(false);
            console.log('error: ', error);
            Swal.fire({
              icon: 'error',
              title: "Failed save new data.",
              showConfirmButton: false,
              timer: 1500
            })
          })
          .finally(() => {
            setIsLoading(false);
            setNewData([]);
          })
      } else if (result.isDenied) {
        Swal.fire('New data are not saved', '', 'info')
      }
    });
  }

  const addfile = (event) => {
    setIsLoading(true);
    let file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      let arrayBuffer = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      let newArr = [];     
      if (arraylist.length !== 0) {
        for (let i = 0; i < arraylist.length; i+=1) {
          let converted_date = new Date(Math.round((arraylist[i].event_date - 25569) * 864e5));
          converted_date = String(converted_date).slice(4, 15)
          let date = converted_date.split(" ")
          let day = date[1];
          let month = date[0];
          month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
          if (month.toString().length <= 1)
              month = '0' + month
          let year = date[2];
          const eventDate = String(day + '/' + month + '/' + year)
          const expireDate = (moment(eventDate, 'DD/MM/YYYY').add(30, 'days'))._d
          newArr.push({
            no: arraylist[i].No,
            npp: arraylist[i].npp,
            name: arraylist[i].name,
            department: arraylist[i].department,
            item: arraylist[i].item,
            amount: arraylist[i].amount,
            event: arraylist[i].event,
            event_date: moment(eventDate, 'DD/MM/YYYY').format("DD/MM/YYYY"),
            expired_date: moment(expireDate).format("DD/MM/YYYY")
          })
        }
      }
      // console.log(moment(newArr[0].expired_date._d).format("DD/MM/YYYY"));
      setNewData(newArr);
      setIsLoading(false);
    };
  };

  const exportExcel = () => {
    setIsLoading(true)
    let finalHeaders = ['No', 'npp', 'name', 'department', 'event', 'item', 'amount', 'event_date'];
    let fixData = [{
      No: 1,
      npp: "34000xxx",
      name: "Anonim",
      department: "Department",
      event: "Genba",
      item: "Voucher/Chocolate",
      amount: "100xxx/1",
      event_date: "exp: 13/03/2023",
    }];

    let ws = XLSX.utils.json_to_sheet(fixData, {header: finalHeaders});
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    let exportFileName = `Month2023_HRperipheral.xls`;
    XLSX.writeFile(wb, exportFileName);
    setIsLoading(false);
  }


  // Table Design
  const excelPreview = () => {
    const columns = [
      {
        Header: 'No',
        id: 'row',
        width: '3%',
        accessor: 'no',
        sortType: 'basic',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.index + 1}</div>,
      },
      {
        Header: 'NPP',
        id: 'npp',
        width: '8%',
        accessor: 'npp',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.npp}</div>
      },
      {
        Header: 'Name',
        id: 'name',
        width: '12%',
        accessor: 'name',
        sortType: 'basic',
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.name}</div>
      },
      {
        Header: 'Department',
        id: 'department',
        width: '10%',
        accessor: 'department',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.department}</div>
      },
      {
        Header: 'Event',
        id: 'event',
        width: '10%',
        accessor: 'event',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.event}</div>
      },
      {
        Header: 'Item',
        id: 'item',
        width: '5%',
        accessor: 'item',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.item}</div>
      },
      {
        Header: 'Amount',
        id: 'amount',
        width: '5%',
        accessor: 'amount',
        sortType: 'basic',
        filter: 'includes',
        Filter: SelectColumnFilter,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.amount}</div>
      },
      {
        Header: 'Event Date',
        id: 'event_date',
        width: '5%',
        accessor: 'event_date',
        sortType: 'datetime',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.event_date}</div>
      },
      {
        Header: 'Expired Date',
        id: 'expire',
        width: '5%',
        disableFilters: true,
        Cell: (cellInfo) => <div className='text-center'>{cellInfo.row.original.expired_date}</div>
      },
    ];

    return (
      <>
        {/* Main card */}
        <div className='row mt-2 row-deck row-cards'>
          <div className="col-md-12">
            <Card styleBody={{ padding: 0, paddingTop: 20 }}>
              <HRTable
                title="Employee"
                columns={columns}
                data={newData}
                formOne={null}
                formTwo={null}
                formThree={null}
                rowSize={[50, 100, 150]}
              />
            </Card>
          </div>
        </div>
      </>
    )
  }

  // View
  const headerView = () => (
    <div className='page-header'>
      <div className='row align-items-center'>
        {/* Title */}
        <div className='col'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item Navbar_title'>Employee / Add Employee</li>
          </ol>
        </div>
      </div>
    </div>
  )

  const chooseView = () => (
    <div className='row row-deck row-cards'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-4 form-group'>
                <input
                  ref={newFile}
                  className='form-control-file form-control'
                  type="file"
                  placeholder="Upload file"
                  accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(event) => addfile(event)}
                  disabled={newData.length !== 0 ? true : false}
                />
              </div>
              <div className='col-md-2 text-align-left'>
                <h4>Format file Excel Workbook (.xlsx)</h4>
              </div>
              <div className='col-md-2'>
                <button
                  type='button' 
                  className='btn btn-success col-md-12'
                  onClick={() => exportExcel() }
                >
                    Download Format
                </button>
              </div>
              <div className='col-md-2'>
                <button 
                  disabled={newData.length === 0 ? true : false}
                  type='button' 
                  className='btn btn-primary col-md-12'
                  onClick={() => {
                    newFile.current.value = ''
                    setNewData([]);
                  }}
                >
                    Reset File
                </button>
              </div>
              <div className='col-md-2'>
                <button 
                  disabled={newData.length === 0 ? true : false}
                  type='button' 
                  className='btn btn-danger col-md-12'
                  onClick={() => storeNewData(newData)}
                >
                    Save New Data
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
   // VIEW
   return (
    <div className='container-fluid'>
      
      {headerView()}
      {chooseView()}

      {/* main content */}
      {isLoading ? <LoadingView /> : excelPreview()}
      
    </div>

  );

}

export default AddEmployee;